import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginOrRegisterComponent } from './login-or-register/login-or-register.component';
//import {DevEntrypointComponent} from './dev-entrypoint/dev-entrypoint.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },

  {
    path: 'login-or-register', component: LoginOrRegisterComponent
  },

  {
    path: '',
    redirectTo: 'home/dev-entrypoint',
    pathMatch: 'full'
  },

  /*{
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },*/
  {
    path: 'fa-fam-sc',
    loadChildren: () => import('./modals/fa-fam-sc/fa-fam-sc.module').then( m => m.FaFamScPageModule)
  },
  {
    path: 'fa-fam-iw',
    loadChildren: () => import('./modals/fa-fam-iw/fa-fam-iw.module').then( m => m.FaFamIwPageModule)
  },
  {
    path: 'fa-fam-me',
    loadChildren: () => import('./modals/fa-fam-me/fa-fam-me.module').then( m => m.FaFamMePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
