import { Component, OnInit } from '@angular/core';

export class LoginGroupValue {
  username: string;
  password: string;
}

export class RegisterGroupValue {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  telephone: string;
  dob?: Date = null;
  streetAddress: string;
  streetNumber: string;
  postcode: string;
  province: string;
  town: string;
}

@Component({
  selector: 'app-login-or-register',
  templateUrl: './login-or-register.component.html',
  styleUrls: ['./login-or-register.component.scss'],
})
export class LoginOrRegisterComponent implements OnInit {

  loginGroupValue: LoginGroupValue;

  registerGroupValue: RegisterGroupValue;

  constructor() { }

  ngOnInit() {

    this.loginGroupValue = new LoginGroupValue();
    this.registerGroupValue = new RegisterGroupValue();

  }

}
